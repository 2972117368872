import cookieService, { getCookieDomain } from 'services/cookies'
import { getCurrentLanguage, getLangFromUrl, getLangFromCookie } from 'utils/current-language'

const getExplodedURL = (): string[] => {
  const explodedURL = window.location.pathname.split('/')
  // Remove empty elements
  // Most probably first and last element as URL have first character as /
  // and trailing slash at the end.
  return explodedURL.filter((el) => {
    return el.length !== 0
  })
}

const redirectToLanguage = (lang: string): void => {
  lang.toLowerCase() === 'pl' || lang.toLowerCase() === 'null' ? (lang = '') : (lang = `${lang}.`)
  window.location.href = `//${lang}${getCookieDomain()}/`
}

/**
 *  Function check if current URL is polish homepage
 *  @return true || false
 */
const isHomepagePL = (): boolean => !!(getExplodedURL().length === 0 && getCurrentLanguage() === 'pl')

const handleDropdownExpand = () => {
  const $dropdownElements = document.querySelectorAll<HTMLDivElement>('[data-lang-dropdown]')
  $dropdownElements.forEach(($dropdownElement) => {
    $dropdownElement.hidden = !$dropdownElement.hidden
  })
}

const handleLanguageChange = (event: Event) => {
  const $selectedElement = event.currentTarget as HTMLLIElement
  const selectedLang = $selectedElement.getAttribute('data-select-lang')
  cookieService.setItem('language', selectedLang, 'Lax')
  redirectToLanguage(selectedLang)
}

export default function initLangSwitcher(): void {
  const $langSwitcherElements = document.querySelectorAll<HTMLDivElement>('[data-lang-switcher]')
  $langSwitcherElements.forEach(($singleLangSwitcher) => {
    $singleLangSwitcher.addEventListener('click', handleDropdownExpand)
  })

  const $langOptions = document.querySelectorAll<HTMLLIElement>('[data-select-lang]')
  $langOptions.forEach(($singleLangElement) => {
    $singleLangElement.addEventListener('click', handleLanguageChange)
  })

  window.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      let cookieLang = String(getLangFromCookie()).toLowerCase()
      const urlLang = String(getLangFromUrl()).toLowerCase()

      if (cookieLang === 'null') {
        cookieService.setItem('language', urlLang, 'Lax')
        cookieLang = urlLang
      }

      if (cookieLang !== urlLang && isHomepagePL()) {
        setTimeout(() => {
          redirectToLanguage(cookieLang)
        }, 50)
      }
    }, 0)
  })
}
