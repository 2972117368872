import cookieService from 'services/cookies'
import settingsService from 'services/settings'
import piwikService from './piwik'
declare const CMS_BASE_URL_IK: string
declare const WBR_URL: string

const isWbrActive = async (): Promise<boolean> => {
  try {
    const settings = await settingsService.fetchWbrSettings()
    return settings['direct-exchange-guest-order'].enabled
  } catch (e) {
    console.warn('wbr is inactive: ', e)
    return false
  }
}

const handleLinkVisibility = (): void => {
  const $links = document.querySelectorAll<HTMLElement>('.wbr-link')

  handleHeaderButton()
  handleSingleRateButton()
  handleLinkEvent()
  $links.forEach(($link: HTMLElement) => {
    $link.classList.remove('wbr-link')
  })
}

const handleLinkEvent = (): void => {
  const $links = document.querySelectorAll<HTMLAnchorElement>('[href="#wbr"]')

  $links.forEach(($link: HTMLAnchorElement) => {
    $link.removeEventListener('click', showNotification)
    $link.setAttribute('href', WBR_URL)
  })
}

function showNotification(): void {
  const popup = document.getElementById('wbr-popup') as HTMLElement
  const close = popup.querySelector<HTMLElement>('.close')
  let timeout = null

  if (popup.hidden) {
    popup.hidden = false

    timeout = setTimeout(() => {
      if (!popup.hidden) popup.hidden = true
    }, 4000)
  }

  close.addEventListener('click', () => {
    if (timeout) clearTimeout(timeout)
    popup.hidden = true
  })
}

const handleDisabledLinkClick = (): void => {
  const links = document.querySelectorAll<HTMLAnchorElement>('[href="#wbr"]')

  links.forEach((link: HTMLAnchorElement) => {
    link.addEventListener('click', showNotification)
  })
}

const makeRedirect = (): void => {
  if (window.location.href === `${CMS_BASE_URL_IK}/wyprobuj-bez-rejestracji/`) {
    window.location.replace(`${CMS_BASE_URL_IK}/jak-to-dziala/`)
  }
}

const handleHeaderButton = (): void => {
  const $wbrHeaderButtons = document.querySelectorAll<HTMLAnchorElement>('.is-wbr-header')
  const isCustomer = cookieService.getItem('isCustomer') === 'true'

  $wbrHeaderButtons.forEach(($btn) => {
    if (isCustomer) $btn.hidden = true
    $btn.addEventListener('click', () => {
      piwikService.pushPiwikNotification(['exchange-without-registration', 'main-page', 'clicked-button'])
    })
  })
}

const handleSingleRateButton = (): void => {
  const WBRCurrencies = ['EUR', 'USD', 'GBP']
  const baseUrl = typeof window !== 'undefined' ? window.DIRECT_LOGIN_BASE_URL_IK : ''
  const registryButton = document.querySelector<HTMLAnchorElement>('[data-wbr-button]')
  const currencyID = registryButton?.getAttribute('data-wbr-button')

  if (registryButton) {
    const calculatorData = {
      soldCurrency: currencyID,
      soldAmount: '',
      boughtCurrency: 'PLN',
      boughtAmount: '100',
      rate: '',
      rateTs: '2018-08-10T11:00:00Z',
      eurEquivalent: null,
      calculateDirection: 'forward',
    }

    const WBRHref = `${baseUrl}?calculatorData=${encodeURIComponent(JSON.stringify(calculatorData))}#/wbr`

    WBRCurrencies.includes(currencyID) ? (registryButton.href = WBRHref) : ''
  }
}

const initWbr = async () => {
  handleDisabledLinkClick()

  const isActive = await isWbrActive()
  isActive ? handleLinkVisibility() : makeRedirect()
}

export default initWbr
