import { GoogleAnalytics } from 'models/google-analitics'
import piwikService from 'services/piwik'
import { pushDataLayer } from 'utils/data-layer-push'
import cookieConsent from 'services/cookies-consent'
declare let ga: (type: string, option: GoogleAnalytics) => void

const anchorAsButtonClick = ($element: HTMLElement): void => {
  const anchorURL = $element instanceof HTMLAnchorElement ? $element.href : ''
  const buttonLabel = $element.textContent || ''

  pushDataLayer('click_button', {
    button_type: 'Button',
    button_text: buttonLabel,
    button_url: anchorURL,
    button_src: window.location.href,
  })
}

const sliderAnchorClick = ($element: HTMLElement): void => {
  const anchorURL = $element instanceof HTMLAnchorElement ? $element.href : ''
  const buttonLabel = $element.textContent || ''

  pushDataLayer('click_button', {
    button_type: 'Slider',
    button_text: buttonLabel,
    button_url: anchorURL,
    button_src: window.location.href,
  })
}

export const cookiesConsent = ($element: HTMLElement): void => {
  const placement = $element.id || 'undefined'
  const acceptedCategories = []

  const consent = {
    necessary: true,
    analytical: cookieConsent.consent.statistics,
    marketing: cookieConsent.consent.marketing,
  }

  if (placement === 'banner-accept-all-button' || placement === 'details-accept-all-button') {
    consent.analytical = true
    consent.marketing = true
  } else if (placement === 'details-reject-all-button' || placement === 'widget-reject-all-button') {
    consent.analytical = false
    consent.marketing = false
  }

  for (const category in consent) {
    if (consent[category]) acceptedCategories.push(category)
  }

  pushDataLayer('cookies_consent', {
    cookies_category: acceptedCategories.join(', '),
    cookies_button_placement: placement,
  })

  piwikService.pushPiwikNotification([
    'cookies_consent',
    'cookies_category',
    acceptedCategories.join(', '),
    'cookies_button_placement',
    placement,
  ])
}

export const cookiesCustomize = ($element: HTMLElement): void => {
  const placement = $element.id || 'undefined'

  pushDataLayer('cookies_customize', {
    cookies_button_placement: placement,
  })

  piwikService.pushPiwikNotification(['cookies_customize', 'cookies_button_placement', placement])
}

const handleTrackingAction = (event: Event): void => {
  const element = event.currentTarget as HTMLElement
  const trackingID = element.getAttribute('data-track-click')

  if (typeof ga === 'undefined') return

  switch (trackingID) {
    case 'anchor-button':
      return anchorAsButtonClick(element)
    case 'slider-button':
      return sliderAnchorClick(element)
    case 'cookies_consent':
      return cookiesConsent(element)
    case 'cookies_customize':
      return cookiesCustomize(element)

    default:
      return console.warn('Unhandled tracking event')
  }
}

export function spawnTrackingEventListners(): void {
  const $trackedButtons = document.querySelectorAll<HTMLElement>('[data-track-click]')
  for (const $singleButton of $trackedButtons) {
    $singleButton.addEventListener('click', handleTrackingAction)
  }
}
