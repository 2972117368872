import {
  checkedValidator,
  emailValidator,
  emptyValidator,
  maxValueValidator,
  minValueValidator,
} from 'services/validators-functions'

export function checkEmail(input: HTMLInputElement, errorBlock: HTMLDivElement): void {
  if (!!emptyValidator(input.value) || !!emailValidator(input.value)) {
    input.classList.add('is-error')
  } else {
    input.classList.remove('is-error')
  }

  errorBlock.innerHTML = emptyValidator(input.value) || emailValidator(input.value) || ''
}

export function checkNumber(
  input: HTMLInputElement,
  errorBlock: HTMLDivElement,
  minValue: string,
  maxValue: string,
): void {
  if (
    !!emptyValidator(input.value) ||
    !!minValueValidator(minValue)(input.value) ||
    !!maxValueValidator(maxValue)(input.value)
  ) {
    input.classList.add('is-error')
  } else {
    input.classList.remove('is-error')
  }

  errorBlock.innerHTML =
    emptyValidator(input.value) ||
    minValueValidator(minValue)(input.value) ||
    maxValueValidator(maxValue)(input.value) ||
    ''
}

export function checkCheckbox(input: HTMLInputElement, errorBlock: HTMLDivElement, checkMark: HTMLDivElement): void {
  if (checkedValidator(input.checked)) {
    checkMark.classList.add('is-error')
  } else {
    checkMark.classList.remove('is-error')
  }

  errorBlock.innerHTML = checkedValidator(input.checked)
}

export function clearValidationError(errorClassElement, errorBlock): void {
  errorClassElement.classList.remove('is-error')
  errorBlock.innerHTML = ''
}
