"use strict";
/** @module Patterns */

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isStreetPattern = exports.isPostalCodePattern = exports.isCharsCompanyPattern = exports.isBankAccountNamePattern = exports.isPasswordPattern = exports.isNumericPattern = exports.isNipPattern = exports.isNamePattern = exports.isLastNamePattern = exports.isGenericTextPattern = exports.isCityPattern = exports.isAlphaNumericPattern = exports.isAccountTypeOtherPattern = exports.isAccountNumberPattern = void 0;

var isAccountNumberPattern = function isAccountNumberPattern(value) {
  var pattern = '^[a-zA-Z0-9\\s]*$';
  return !!value.match(pattern);
};

exports.isAccountNumberPattern = isAccountNumberPattern;
/**
 * Checks if value matches account number pattern
 * <pre> /^[a-zA-Z0-9\\s]*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isAccountTypeOtherPattern = function isAccountTypeOtherPattern(value) {
  var pattern = '^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ. ]*$';
  return !!value.match(pattern);
};

exports.isAccountTypeOtherPattern = isAccountTypeOtherPattern;
/**
 * Checks if value matches account type pattern
 * <pre> /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ. ]*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isAlphaNumericPattern = function isAlphaNumericPattern(value) {
  var pattern = '^[a-zA-Z0-9]*$';
  return !!value.match(pattern);
};

exports.isAlphaNumericPattern = isAlphaNumericPattern;
/**
 * Checks if value matches alpha numeric pattern
 * <pre> /^[a-zA-Z0-9]*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isCityPattern = function isCityPattern(value) {
  var pattern = "^([a-zA-Z\x80-\u024F]+(?:. |-| |'))*[a-zA-Z\x80-\u024F]*$";
  return !!value.match(pattern);
};

exports.isCityPattern = isCityPattern;
/**
 * Checks if value matches city pattern
 * <pre> /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isGenericTextPattern = function isGenericTextPattern(value) {
  var pattern = '^[0-9a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\\s\\,\\.\\-\\+\\(\\)\\!\\?\\:\\@\\n]*$';
  return !!value.match(pattern);
};

exports.isGenericTextPattern = isGenericTextPattern;
/**
 * Checks if value matches generic text pattern
 * <pre> /^[0-9a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s\,\.\-\+\(\)\!\?\:\@\n]*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isLastNamePattern = function isLastNamePattern(value) {
  var pattern = "^[a-zA-Z\\u00C0-\\u017F\\-\\s\\']*$";
  return !!value.match(pattern);
};

exports.isLastNamePattern = isLastNamePattern;
/**
 * Checks if value matches last name pattern
 * <pre> /^[a-zA-Z\u00C0-\u017F\-\s\']*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isNamePattern = function isNamePattern(value) {
  var pattern = '^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ ]*$';
  return !!value.match(pattern);
};

exports.isNamePattern = isNamePattern;
/**
 * Checks if value matches name pattern
 * <pre> /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ ]*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isNipPattern = function isNipPattern(value) {
  var pattern = '^[a-zA-Z0-9\\s\\-]*$';
  return !!value.match(pattern);
};

exports.isNipPattern = isNipPattern;
/**
 * Checks if value matches nip pattern
 * <pre> /^[a-zA-Z0-9\s\-]*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isNumericPattern = function isNumericPattern(value) {
  var pattern = '^[0-9]*$';
  return !!value.match(pattern);
};

exports.isNumericPattern = isNumericPattern;
/**
 * Checks if value matches numeric pattern
 * <pre> /^[0-9]*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isPasswordPattern = function isPasswordPattern(value) {
  var pattern = '^[0-9a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ' + '\\s\\-\\+\\_\\\\!\\@\\#\\$\\%\\^&\\*\\|\\"\\\'\\;\\:\\,\\.\\?\\(\\)\\[\\]\\{\\}]*$';
  return !!value.match(pattern);
};

exports.isPasswordPattern = isPasswordPattern;
/**
 * Checks if value matches password pattern
 * <pre> /^[0-9a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\s\-\+\_\\!\@\#\$\%\^&\*\|\"\'\;\:\,\.\?\(\)\[\]\{\}]*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isBankAccountNamePattern = function isBankAccountNamePattern(value) {
  var pattern = "^[0-9a-zA-Z\\u00C0-\\u017F\\xad\\xa0\\u201c\\u201d\\u201e\u201F\\u2013" + '\\_\\;\\\'\\@\\-\\s\\",.\\/\\-\\+\\&\\(\\)]*$';
  return !!value.match(pattern);
};

exports.isBankAccountNamePattern = isBankAccountNamePattern;
/**
 * Checks if value matches bank account name pattern
 * <pre>
 *   /^[0-9a-zA-Z\u00C0-\u017F\xad\xa0\u201c\u201d\u201e\u201f\u2013\_\;\'\@\-\s\",.\/\-\+\&\(\)]*$/
 * </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isCharsCompanyPattern = function isCharsCompanyPattern(value) {
  var pattern = "^[a-zA-Z0-9\\u00C0-\\u017F\\xad\\xa0\\u201c\\u201d\\u201e\u201F\\u2013" + '\\_\\;\\@\\-\\s\\.\\,\\&\\`\\\'\\:\\(\\)\\!\\*\\"\\/\\\\\\+]*$';
  return !!value.match(pattern);
};

exports.isCharsCompanyPattern = isCharsCompanyPattern;
/**
 * Checks if value matches company name pattern
 * <pre>
 *   /^[a-zA-Z0-9\u00C0-\u017F\xad\xa0\u201c\u201d\u201e\u201f\u2013\_\;\@\-\s\.\,\&\`\'\:\(\)\!\*\"\/\\\+]*$/
 * </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isPostalCodePattern = function isPostalCodePattern(value) {
  var pattern = '^[a-zA-Z0-9 \\-]*$';
  return !!value.match(pattern);
};

exports.isPostalCodePattern = isPostalCodePattern;
/**
 * Checks if value matches postal code pattern
 * <pre> /^[a-zA-Z0-9 \-]*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */

var isStreetPattern = function isStreetPattern(value) {
  var pattern = "^[0-9a-zA-Z\\u00C0-\\u017F\\-\\s\\.,\\/]*$";
  return !!value.match(pattern);
};

exports.isStreetPattern = isStreetPattern;
/**
 * Checks if value matches street name pattern
 * <pre> /^[0-9a-zA-Z\u00C0-\u017F\-\s\.,\/]*$/ </pre>
 * @param {string} value - value to check.
 * @returns {boolean}
 */