import { toggleClass } from 'utils/toggle-classlist'
import piwikService from 'services/piwik'

declare global {
  interface Window {
    __lc: any
    ___gcfg: any
    LiveChatWidget: any
  }
}

const $floaterBox = document.querySelector<HTMLDivElement>('[data-js-selector="floater-box"]')
const $floaterButton = document.querySelector<HTMLDivElement>('[data-js-selector="floater-button"]')
const $livechatButtons = document.querySelectorAll<HTMLElement>('[data-js-selector="livechat-button"]')
const liveChatStatus = sessionStorage.getItem('livechat_opened')

function initLivechatParams(): void {
  window.__lc = window.__lc || {}
  window.__lc.license = 1085871
  window.__lc.asyncInit = true
  ;(function (n, t, c) {
    function i(n) {
      return e._h ? e._h.apply(null, n) : e._q.push(n)
    }
    const e = {
      _q: [],
      _h: null,
      _v: '4.0',
      on() {
        i(['on', c.call(arguments)])
      },
      call() {
        i(['call', c.call(arguments)])
      },
      init() {
        const n = t.createElement('script')
        ;(n.async = !0),
          (n.type = 'text/javascript'),
          (n.src = 'https://cdn.livechatinc.com/tracking.js'),
          t.head.appendChild(n)
      },
    }
    !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e)
  })(window, document, [].slice)
}

function onBoxClick(): void {
  toggleClass($floaterBox, 'is-open')
  toggleClass($floaterButton, 'is-open')

  if ($floaterButton.classList.contains('is-open')) {
    piwikService.pushPiwikNotification(['FastContact', 'Open', 'Frontpage'])
  }
}

function onVisibilityChanged(data) {
  switch (data.visibility) {
    case 'maximized':
      sessionStorage.setItem('livechat_opened', 'true')
      break
    case 'hidden':
      sessionStorage.removeItem('livechat_opened')
      break
    default:
      break
  }
}

function initLivechat(): void {
  initLivechatParams()
  window.LiveChatWidget.init()
  window.LiveChatWidget.call('maximize')
  window.LiveChatWidget.on('visibility_changed', onVisibilityChanged)
  sessionStorage.setItem('livechat_opened', 'true')
}

export function initFastContact(): void {
  if (liveChatStatus) initLivechat()

  $floaterButton?.addEventListener('click', onBoxClick)
  $livechatButtons?.forEach(($button) => {
    $button.addEventListener('click', initLivechat)
  })

  if (location.search.includes('chat')) {
    initLivechat()
    return
  }
}
