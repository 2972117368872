declare global {
  interface Window {
    _paq: any
  }
}

const DEFAULT_PIWIK_DATA = ['trackEvent']

const piwikService = {
  pushPiwikNotification: (data: string[]): void => window._paq && window._paq.push([...DEFAULT_PIWIK_DATA, ...data]),
}

export default piwikService
