"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ibanHelper = void 0;
exports.ibanHelper = {
    getCurrentCountryCode: function (iban) { return (iban && iban.substr(0, 2).replace(/\d/g, '')); },
    sanitize: function (iban) { return iban ? iban.replace(/\s/g, '') : null; },
    checkIbanLength: function (accountNumber, countryCode) {
        var sanitizedNumber = accountNumber.replace(/ /g, '');
        var isPrefix = sanitizedNumber.substr(0, 2).match(/[A-Z]/i);
        return (countryCode === 'PL' && isPrefix && sanitizedNumber.length === 28)
            || (countryCode === 'PL' && !isPrefix && sanitizedNumber.length === 26)
            || (countryCode !== 'PL' && sanitizedNumber.length > 12);
    },
    checkIban: function (iban, countryCode) {
        iban = iban.toUpperCase().replace(/ /g, '');
        if (iban.length < 4) {
            return false;
        }
        // If country code is given, country code in iban should be the same as the given one
        // Or if there is no country code in iban, we add the given one
        if (countryCode) {
            var currentCountryCode = exports.ibanHelper.getCurrentCountryCode(iban);
            if (currentCountryCode) {
                if (currentCountryCode !== countryCode) {
                    return false;
                }
            }
            else {
                iban = "" + countryCode + iban;
            }
        }
        iban = iban.substr(4) + iban.substr(0, 4);
        var num = '';
        for (var i in iban) {
            if (iban.hasOwnProperty(i)) {
                var c = iban[i];
                if (c >= '0' && c <= '9') {
                    num += c;
                }
                else if (c >= 'A' && c <= 'Z') {
                    num += "" + (c.charCodeAt(0) - 55);
                }
                else {
                    return false;
                }
            }
        }
        return exports.ibanHelper.iso7064Mod97_10(num) === 1;
    },
    iso7064Mod97_10: function (iban) {
        var remainder = iban;
        var block;
        while (remainder.length > 2) {
            block = remainder.slice(0, 9);
            remainder = parseInt(block, 10) % 97 + remainder.slice(block.length);
        }
        return parseInt(remainder, 10) % 97;
    },
    getCompleteIban: function (iban, countryCode) {
        if (countryCode && iban) {
            var currentCountryCode = exports.ibanHelper.getCurrentCountryCode(iban);
            if (!currentCountryCode) {
                iban = "" + countryCode + iban;
            }
        }
        return exports.ibanHelper.sanitize(iban);
    },
};
