// Important to notice:
// This file is attached as a "defer".
// Therefore, we do not need to wrap the code into the DOMContentLoaded event.
// https://i.stack.imgur.com/OovG7.png

// Import global scope scripts
import { initExitModal } from 'components/exit-modal'
import initLangSwitcher from 'components/lang-switcher'
import { initFastContact } from 'components/fast-contact'
import { initMobileNav } from 'components/mobile-nav'
import { spawnTrackingEventListners } from 'components/tracking-ga-events'
import initWbr from 'services/wbr'
import cookieConsent from 'services/cookies-consent'

// init lang switcher
initLangSwitcher()

// Init wbr link
initWbr()

// Init exit modal
initExitModal()

// Init Fast Contact floater
initFastContact()

// Init mobile navigation
initMobileNav()

// set GA/GTM events tracking
spawnTrackingEventListners()

// cookie consent
cookieConsent.init()
