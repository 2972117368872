import { isPassiveSupported } from 'utils/passive-listner'

const $menuButtonForOpening = document.querySelector('[data-mobile-nav-button]') as HTMLButtonElement
const $menuButtonForClosing = document.querySelector('[data-mobile-nav-button-close]') as HTMLButtonElement
const $menuOverlay = document.querySelector('[data-mobile-nav-overlay]') as HTMLDivElement
const $menu = document.querySelector('[data-mobile-nav]') as HTMLDivElement
const $allExpendableContainers = [...document.querySelectorAll<HTMLElement>('[data-expandable-container]')]

const closePageMenu = (): void => {
  $menuButtonForOpening.setAttribute('aria-expanded', 'false')
  $menuOverlay.classList.remove('is-expanded')
  $menu.classList.remove('is-expanded')
  setTimeout(() => {
    $menuOverlay.hidden = true
    $menu.hidden = true
  }, 600)
}

const openPageMenu = (): void => {
  $menuButtonForOpening.setAttribute('aria-expanded', 'true')
  $menuOverlay.hidden = false
  $menu.hidden = false
  setTimeout(() => {
    $menuOverlay.classList.add('is-expanded')
    $menu.classList.add('is-expanded')
  }, 15)
}

const bindEventsForButtons = (): void => {
  $menuButtonForOpening?.addEventListener('click', openPageMenu, isPassiveSupported() ? { passive: true } : false)
  $menuButtonForClosing?.addEventListener('click', closePageMenu, isPassiveSupported() ? { passive: true } : false)
  $menuOverlay?.addEventListener('click', closePageMenu, isPassiveSupported() ? { passive: true } : false)
}

const expandContainer = ($expandContainer: HTMLElement, $container: HTMLElement): void => {
  $expandContainer.setAttribute('aria-expanded', 'true')
  $expandContainer.classList.add('is-expanded')
  $container.querySelector('[data-chevron]')?.classList.add('is-rotated')
}

const collapseContainer = ($expandContainer: HTMLElement, $container: HTMLElement): void => {
  $expandContainer.setAttribute('aria-expanded', 'false')
  $expandContainer.classList.remove('is-expanded')
  $container.querySelector('[data-chevron]')?.classList.remove('is-rotated')
}

function toogleExpendableContainer(event: Event): void {
  const target = event.target as HTMLAnchorElement
  event.stopPropagation()
  if (target.getAttribute('href') === '#') event.preventDefault()
  if (event.target !== event.currentTarget && target.getAttribute('href') !== '#') return

  const $expendableContainer = event.currentTarget as HTMLElement
  const $containerToExpand = $expendableContainer.querySelector('[data-expand-group]') as HTMLElement

  $containerToExpand.getAttribute('aria-expanded') === 'false'
    ? expandContainer($containerToExpand, $expendableContainer)
    : collapseContainer($containerToExpand, $expendableContainer)
}

const initFolding = (): void => {
  for (const $singleExpendableElement of $allExpendableContainers) {
    $singleExpendableElement.addEventListener('click', toogleExpendableContainer)
  }
}

const expandCurrentActive = (): void => {
  for (const $singleExpendableElement of $allExpendableContainers) {
    if ($singleExpendableElement.classList.contains('current-menu-ancestor')) {
      expandContainer(
        $singleExpendableElement.querySelector<HTMLElement>('[data-expand-group]'),
        $singleExpendableElement,
      )
    }
  }
}

export function initMobileNav(): void {
  bindEventsForButtons()
  initFolding()
  expandCurrentActive()
}
