import { checkedValidator, emailValidator, emptyValidator } from 'services/validators-functions'
import storageService from 'services/storage'
import { promiseWrapper } from 'utils/promise'
import { toggleClass } from 'utils/toggle-classlist'
import { checkCheckbox, checkEmail, clearValidationError } from 'services/input-validators'
import { GoogleAnalytics } from 'models/google-analitics'
import { toggleSpinner } from 'components/button-with-spinner'
import { pushDataLayer } from 'utils/data-layer-push'
import { getCurrentLanguage } from 'utils/current-language'

declare let USER_BASE_URL_IK: string
declare let ga: (type: string, option: GoogleAnalytics) => void

interface FormPayload {
  email: string
  language: string
}

const $form = document.querySelector<HTMLFormElement>('[data-js-selector="popup-form"]')
const $serverErrorWrapper = document.querySelector<HTMLDivElement>('[data-js-selector="server-error-message"]')
const $accountExistsErrorWrapper = document.querySelector<HTMLDivElement>('[data-js-selector="account-exists-message"]')
const $modalStep1 = document.querySelector<HTMLDivElement>('[data-js-selector="promo-popup__step1"]')
const $modalStep2 = document.querySelector<HTMLDivElement>('[data-js-selector="promo-popup__step2"]')
const $submitButton = document.querySelector<HTMLButtonElement>('[data-js-selector="popup-submit-button"]')

const $checkboxHolder = document.querySelector<HTMLDivElement>('[data-js-selector="popup-checkbox-holder"]')
const $checkboxInput = document.getElementById('rodo-checkbox') as HTMLInputElement
const $checkmarkWrapper = document.querySelector<HTMLDivElement>('[data-js-selector="checkmark-wrapper"]')
const $checkmarkIcon = document.querySelector<HTMLDivElement>('[data-js-selector="checkmark-icon"]')
const $checkboxErrorWrapper = document.querySelector<HTMLDivElement>('[data-js-selector="checkbox-error-message"]')

const $modal = document.querySelector('[data-js-selector="promo-popup"]') as HTMLElement
const $modalCloseIcon = $modal?.querySelector<HTMLDivElement>('[data-js-selector="modal-close-icon"]') || null
const $modalCloseButton = $modal?.querySelector<HTMLDivElement>('[data-js-selector="modal-close-button"]') || null

const $emailInput = document.querySelector('[name="popup-email"]') as HTMLInputElement
const $emailErrorWrapper = $form?.querySelector<HTMLDivElement>('[data-js-selector="email-error-message"]')

function closeModal() {
  storageService.setItem('promo_popup_closed', 'true')
  $modal.hidden = true
  if (typeof ga !== 'undefined') {
    ga('send', {
      hitType: 'event',
      eventCategory: 'Marketing',
      eventAction: 'Exit Popup',
      eventLabel: 'closed',
    })
  }
}

function showModal(): void {
  if (!storageService.getItem('promo_popup_closed') && $modal.hidden) {
    setTimeout(() => {
      $modal.hidden = false
      if (typeof ga !== 'undefined') {
        ga('send', {
          hitType: 'event',
          eventCategory: 'Marketing',
          eventAction: 'Exit Popup',
          eventLabel: 'impression',
          nonInteraction: true,
        })
      }
    }, 10000)
  }
}

function toggleCheckbox(): void {
  toggleClass($checkmarkWrapper, 'is-checked')
  $checkmarkIcon.toggleAttribute('hidden')
}

function showCheckbox(): void {
  if (!emptyValidator($emailInput.value) && !emailValidator($emailInput.value)) {
    $checkboxHolder.classList.remove('is-collapsed')
  }
}

function clearError(event): void {
  if (event.target.name === 'popup-email') {
    clearValidationError($emailInput, $emailErrorWrapper)
  } else if (event.target.name === 'rodo-checkbox') {
    clearValidationError($checkmarkWrapper, $checkboxErrorWrapper)
  }
}

function isFormValid(): boolean {
  return (
    !emptyValidator($emailInput.value) &&
    !emailValidator($emailInput.value) &&
    !checkedValidator($checkboxInput.checked)
  )
}

function onSubmit(event: Event): void {
  event.preventDefault()

  checkEmail($emailInput, $emailErrorWrapper)
  if (!$checkboxHolder.classList.contains('is-collapsed')) {
    checkCheckbox($checkboxInput, $checkboxErrorWrapper, $checkmarkWrapper)
  }

  if (isFormValid()) {
    const payload = {
      email: $emailInput.value,
      language: getCurrentLanguage(),
    }
    toggleSpinner($submitButton)
    sendData(payload)
      .then(() => {
        toggleSpinner($submitButton)
        $modalStep1.toggleAttribute('hidden')
        $modalStep2.toggleAttribute('hidden')
        storageService.setItem('promo_popup_closed', 'true')

        pushDataLayer('form_submitted', { form_name: 'Formularz exit popup' })

        if (typeof ga !== 'undefined') {
          ga('send', {
            hitType: 'event',
            eventCategory: 'Marketing',
            eventAction: 'Exit Popup',
            eventLabel: 'success',
          })
        }
      })
      .catch((error) => {
        toggleSpinner($submitButton)
        !!error && error.status === 422
          ? ($accountExistsErrorWrapper.hidden = false)
          : ($serverErrorWrapper.hidden = false)
      })
  }
}

function sendData(payload: FormPayload): Promise<void> {
  return promiseWrapper<void, FormPayload>(`${USER_BASE_URL_IK}/api/public/cmsPopupPromoCodeEmail`, 'POST', payload)
}

export function initExitModal(): void {
  if ($modalCloseIcon !== null) {
    $modalCloseIcon?.addEventListener('click', closeModal)
    $modalCloseButton?.addEventListener('click', closeModal)
    $form?.addEventListener('submit', onSubmit)
    $checkboxInput?.addEventListener('change', toggleCheckbox)
    $checkboxInput?.addEventListener('change', clearError)
    $emailInput?.addEventListener('input', clearError)
    $emailInput?.addEventListener('input', showCheckbox)
    showModal()
  }
}
