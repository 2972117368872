export const isPassiveSupported = (): boolean | {} => {
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get() {
          return { passive: true }
        },
      }),
    )
  } catch (err) {
    return false
  }

  return true
}
